import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
    const [username, usernameupdate] = useState('');
    const [password, passwordupdate] = useState('');
    const [validationError, setValidationError] = useState(null);
    const usenavigate=useNavigate();

    useEffect(()=>{
sessionStorage.clear();
    },[]);

   

    const ProceedLoginusingAPI = async(e) => {
        e.preventDefault();
        if (validate()) {
            ///implentation
            const formData = new FormData();
			formData.append('username', username);
      		formData.append('password', password);
			const response = await fetch('https://filemanager.3plains.com/fmsauth.php', {
				method : 'POST',
				body : formData
			});

			const responseData = await response.json();			
      		if (responseData.valid == 0) {
                setValidationError('Login failed, invalid credentials');
            } else {
                setValidationError('Login suceeded: userID = '+responseData.userID);
                    sessionStorage.setItem('username',username);
                    sessionStorage.setItem('userID',responseData.userID);
                    sessionStorage.setItem('webID',responseData.webID);
                    usenavigate('/');
                
            }
            
            
        }
    }
    const validate = () => {
        let result = true;
        if (username === '' || username === null) {
            result = false;
            setValidationError('Please Enter Username');
            return result;
        }
        if (password === '' || password === null) {
            result = false;
            setValidationError('Please Enter Password');
            return result;
        }
        return result;
    }
    return (
        <div className="row">
            <div className="offset-lg-3 col-lg-6" style={{ marginTop: '10%' }}>
                <div id="3plogo"><img src="images/logo-white.png" className="logo-header" /></div>
                <form onSubmit={ProceedLoginusingAPI} className="login-box">
                    <div className="">
                        <div className="login-header">
                            <strong>RESOURCES LOGIN</strong>
                        </div>
                        <div>{validationError && (
                            	<p className="text-danger">{validationError}</p>
                            )}
                        </div>
                        <div className="login-group">
                            <input value={username} onChange={e => usernameupdate(e.target.value)} className="login-input" placeholder="Email Address"></input>
                            <input type="password" value={password} onChange={e => passwordupdate(e.target.value)} className="login-input" placeholder="Password"></input>
                        </div>
                        <div className="">
                            <button type="submit" className="login-btn">LOGIN</button>                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;