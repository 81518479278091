import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs'; 
import Tab from 'react-bootstrap/Tab'; 
const Home = () => {
    const usenavigate = useNavigate();

    const gotoUploads = () => {
        usenavigate('/upload');
      };

    return (
        <div>
        <main>
            <div class="grid">
                <div class="grid-item two">
                    <a className="" href="/upload">Upload Files</a>
                </div>
                <div class="grid-item two">
                    <a className="" href="https://portal.3plains.com/login/website/" target="_blank">Manage Website</a>
                </div>
                <div class="grid-item two">
                    <a className="" href="https://www.3plains.com/help/" target="_blank">Help</a>
                </div>
                <div class="grid-item two">
                    <a className="" href="https://portal.3plains.com/login/account/subscriptions/" target="_blank">Billing</a>
                </div>
            </div>
            {/*<ul className="navbar-nav ms-lg-5 me-lg-auto">
                <li className="nav-item">
                    <a className="nav-link click-scroll" href="#section_1">Home</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/upload">Upload Files</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://portal.3plains.com" target="_blank">Manage Files</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link click-scroll" href="#section_2">Help</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link click-scroll" href="https://portal.3plains.com" target="_blank">Billing</a>
                </li>
            </ul>
            <section className="hero-section d-flex justify-content-center align-items-center" id="section_1">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12 mx-auto">
                            <h1 className="text-white text-center">3plains Resources Manager</h1>

                            <h6 className="text-center">Customer File Management System</h6>

                          
                        </div>

                    </div>
                </div>
            </section>


            
        <section className="featured-section">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                            <div className="custom-block bg-white shadow-lg">
                                
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">Welcome!</h5>

                                            <p className="mb-0">Welcome to your Resources Manager Portal!<br /><br />
                                                The following portal includes an upload tool, a file manager, file history, resources, and more.
                                                 
                                            </p>
                                        </div>

                                    </div>

                               
                            </div>
                        </div>

                        <div className="col-lg-6 col-12">
                            <div className="custom-block custom-block-overlay">
                                <div className="d-flex flex-column h-100">
                                    <img src="images/businesswoman-using-tablet-analysis.jpg" className="custom-block-image img-fluid" alt="" />

                                    <div className="custom-block-overlay-text d-flex">
                                        <div className="col-12">
                                            <h5 className="text-white mb-2">Alerts & Messages</h5>

                                            
                                                <table className="table table-hover" width="100%">
                                                    <thead>
                                                      <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Message</th>
                                                        <th scope="col">Action</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <th scope="row">5/1/2024 4:35 PM</th>
                                                        <td>Mark Smith was notified about yur upload.</td>
                                                        <td><i className="bi bi-trash"></i></td>
                                                     
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">5/1/2024 2:02 PM</th>
                                                        <td>You uploaded 20240501_filetest.jpg</td>
                                                        <td><i className="bi bi-trash"></i></td>
                                                      
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">4/30/2024 4:12 PM</th>
                                                        <td>You uploaded 20240430_portfolio1.jpg</td>
                                                        <td><i className="bi bi-trash"></i></td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                            
                                            <a href="/messages" className="btn custom-btn mt-2 mt-lg-3">View All</a>
                                        </div>

                                        <span className="badge bg-finance rounded-pill ms-auto">3</span>
                                    </div>

                                    <div className="section-overlay"></div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </section>*/}
            <section className="explore-section section-padding" id="section_2">
                <div className="container">
                    <div className="col-12 text-center">
                        <h2 className="mb-4">Help</h2>
                    </div>
                </div>
                <Tabs defaultActiveKey="second"> 
                    <Tab eventKey="first" title="Add Phone(iOS) Icon"> 
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="custom-block bg-white shadow-lg">
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">Step 1:  Select site</h5>
                                            <p className="mb-0">tap the Share button <img src="https://help.apple.com/assets/6598817BD84F513BE609FC12/6598817CFF5E0690C7035CD9/en_US/d26fe35d3438fe81179a80c2b6c9b0c9.png" alt="the Share button" height="30" width="25" originalimagename="GlobalArt/IL_ShareBlue.png" /> in the menu bar</p>
                                        </div>
                                    </div>
                                    <img src="images/click_phone.png" className="custom-block-image img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="custom-block bg-white shadow-lg">
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">Step 2: Add to Home</h5>
                                            <p className="mb-0">Scroll down the list of options, then tap Add to Home Screen.</p>
                                        </div>
                                    </div>
                                    <img src="https://help.apple.com/assets/6598817BD84F513BE609FC12/6598817CFF5E0690C7035CD9/en_US/a0e4cdb7f86cb08e08f489bf42cf8867.png" alt="In Safari, the Share button on a website has been tapped, displaying a list of options, including Add to Home Screen." height="499" width="281" originalimagename="Art/S3914_sfriWebsiteHomeScreen_iPhone.png" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="custom-block bg-white shadow-lg">
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">Step 3: Verify Icon</h5>
                                            <p className="mb-0">The icon appears only on the device where you add it.  If you don't see Add to Home Screen, you can add it. Scroll down to the bottom of the list, tap Edit Actions, then tap  Add to Home Screen.</p>
                                        </div>
                                    </div>
                                    <img src="images/tablet.png" className="custom-block-image img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="second" title="How to Upload"> 
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
                                <div className="custom-block bg-white shadow-lg">
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">step 1</h5>
                                            <p className="mb-0">Click Upload Files button above</p>
                                        </div>
                                    </div>    
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
                                <div className="custom-block bg-white shadow-lg">
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">step 2</h5>
                                            <p className="mb-0">Drag and drop files to the box, or click in the box to choose files</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="custom-block bg-white shadow-lg">
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">step 3</h5>
                                            <p className="mb-0">Files will upload and screen will refresh when finished. 3plains will be notified of your uploads!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab> 
                    <Tab eventKey="third" title="Alerts & Messages"> 
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="custom-block bg-white shadow-lg">
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">3plains Alerts</h5>
                                            <p className="mb-0">No Alerts at this time!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="custom-block custom-block-overlay">
                                    <div className="d-flex flex-column h-100">
                                        <img src="images/businesswoman-using-tablet-analysis-graph-company-finance-strategy-statistics-success-concept-planning-future-office-room.jpg" className="custom-block-image img-fluid" alt="" />
                                        <div className="custom-block-overlay-text d-flex">
                                            <div>
                                                <h5 className="text-white mb-2">Messages</h5>
                                                <p className="text-white">No new messages currently</p>
                                            </div>
                                        </div>
                                        <div className="section-overlay"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab> 
                </Tabs> 
                {/* <div className="container-fluid">
                    <div className="row">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="design-tab" data-bs-toggle="tab" data-bs-target="#addicon-tab-pane" type="button" role="tab" aria-controls="addicon-tab-pane" aria-selected="true">Add Phone(iOS) Icon</button>
                            </li>

                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="marketing-tab" data-bs-toggle="tab" data-bs-target="#uploadhelp-tab-pane" type="button" role="tab" aria-controls="uploadhelp-tab-pane" aria-selected="false">How to Upload</button>
                            </li>

                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="finance-tab" data-bs-toggle="tab" data-bs-target="#alertshelp-tab-pane" type="button" role="tab" aria-controls="alertshelp-tab-pane" aria-selected="false">Alerts & Messages</button>
                            </li>

                           
                        </ul>
                    </div>
                </div>

                <div className="container">
                    <div className="row">

                        <div className="col-12">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="addicon-tab-pane" role="tabpanel" aria-labelledby="design-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                                            <div className="custom-block bg-white shadow-lg">
                                                
                                                    <div className="d-flex">
                                                        <div>
                                                            <h5 className="mb-2">Step 1:  Select site</h5>

                                                            <p className="mb-0">tap the Share button <img src="https://help.apple.com/assets/6598817BD84F513BE609FC12/6598817CFF5E0690C7035CD9/en_US/d26fe35d3438fe81179a80c2b6c9b0c9.png" alt="the Share button" height="30" width="25" originalimagename="GlobalArt/IL_ShareBlue.png" /> in the menu bar</p>
                                                        </div>

                                                    </div>

                                                    <img src="images/click_phone.png" className="custom-block-image img-fluid" alt="" />
                                                    
                                                
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                                            <div className="custom-block bg-white shadow-lg">
                                                
                                                    <div className="d-flex">
                                                        <div>
                                                            <h5 className="mb-2">Step 2: Add to Home</h5>

                                                                <p className="mb-0">Scroll down the list of options, then tap Add to Home Screen.</p>
                                                        </div>

                                                    </div>
                                                    
                                                    <img src="https://help.apple.com/assets/6598817BD84F513BE609FC12/6598817CFF5E0690C7035CD9/en_US/a0e4cdb7f86cb08e08f489bf42cf8867.png" alt="In Safari, the Share button on a website has been tapped, displaying a list of options, including Add to Home Screen." height="499" width="281" originalimagename="Art/S3914_sfriWebsiteHomeScreen_iPhone.png" />
                                                      
                                                
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="custom-block bg-white shadow-lg">
                                                
                                                    <div className="d-flex">
                                                        <div>
                                                            <h5 className="mb-2">Step 3: Verify Icon</h5>

                                                                <p className="mb-0">The icon appears only on the device where you add it.  If you don't see Add to Home Screen, you can add it. Scroll down to the bottom of the list, tap Edit Actions, then tap  Add to Home Screen.</p>
                                                        </div>

                                                    </div>

                                                    
                                                    <img src="images/tablet.png" className="custom-block-image img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="uploadhelp-tab-pane" role="tabpanel" aria-labelledby="marketing-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
                                                <div className="custom-block bg-white shadow-lg">
                                                    
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">step 1</h5>

                                                                <p className="mb-0">Lorem Ipsum dolor sit amet consectetur</p>
                                                            </div>

                                                        </div>
                                                   
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
                                                <div className="custom-block bg-white shadow-lg">
                                                    
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">step 2</h5>

                                                                <p className="mb-0">Lorem Ipsum dolor sit amet consectetur</p>
                                                            </div>

                                                        </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-12">
                                                <div className="custom-block bg-white shadow-lg">
                                                    
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">step 3</h5>

                                                                <p className="mb-0">Lorem Ipsum dolor sit amet consectetur</p>
                                                            </div>

                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                  </div>

                                <div className="tab-pane fade" id="alertshelp-tab-pane" role="tabpanel" aria-labelledby="finance-tab" tabIndex="0">   
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12 mb-4 mb-lg-0">
                                            <div className="custom-block bg-white shadow-lg">
                                                
                                                    <div className="d-flex">
                                                        <div>
                                                            <h5 className="mb-2">Alerts</h5>

                                                            <p className="mb-0">Lorem Ipsum dolor sit amet consectetur</p>
                                                        </div>

                                                    </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="custom-block custom-block-overlay">
                                                <div className="d-flex flex-column h-100">
                                                    <img src="images/businesswoman-using-tablet-analysis-graph-company-finance-strategy-statistics-success-concept-planning-future-office-room.jpg" className="custom-block-image img-fluid" alt="" />

                                                    <div className="custom-block-overlay-text d-flex">
                                                        <div>
                                                            <h5 className="text-white mb-2">Messages</h5>

                                                            <p className="text-white">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint animi necessitatibus aperiam repudiandae nam omnis</p>

                                                        </div>

                                                    </div>

                                                    

                                                    <div className="section-overlay"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            
        </main>
        </div>
    );
}

export default Home;