import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Appheader = () => {
    const [displayusername, displayusernameupdate] = useState('');
    const [showmenu, showmenuupdateupdate] = useState(false);
    const usenavigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/login') {
            showmenuupdateupdate(false);
        } else {
            showmenuupdateupdate(true);
            let username = sessionStorage.getItem('username');
            if (username === '' || username === null) {
                usenavigate('/login');
            } else {
                displayusernameupdate(username);
            }
        }

    }, [location])
    return (
        <div>
            {
            showmenu &&    
            /*<Navbar expand="lg" className="m-auto bg-secondary fixed-top">
                <Container style={{paddingLeft: "17%"}}>
                <Navbar.Brand href="#top">Resources</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    <Nav.Link href="/#section_1">Home</Nav.Link>
                    <Nav.Link href="/upload">Uploads</Nav.Link>
                    <Nav.Link href="https://www.3plains.com/help/" target="_blank">Help</Nav.Link>
                    <Nav.Link href="https://portal.3plains.com/login/website/" target="_blank">Manage Website</Nav.Link>
                    <Nav.Link href="https://portal.3plains.com/login/account/subscriptions/" target="_blank">Billing</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>*/
            <div className="main-nav">
                <a href="/"><img src="images/logo.png" className="logo-header" /></a>
            </div>
            }
        </div>
    );
}

export default Appheader;