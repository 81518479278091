import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {useDropzone} from 'react-dropzone';
import ReactPaginate from "react-paginate";


import ReactTable from "react-table";
import { ReactTableDefaults } from "react-table";

//Set default values of React Table
// Object.assign(ReactTableDefaults, {
//     multiSort: false
//   });

const Upload = () => {
    const usenavigate = useNavigate();

    const [items, setItems] = useState([]);

    const [pageCount, setpageCount] = useState(0);

    let limit = 10;

    const [selectedImages, setSelectedImages] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);

	const [imageLink, setImageLink] = useState(null);

	const [validationError, setValidationError] = useState(null);

    const [posts, setPosts] = useState([]);

	const fileInputRef = useRef(null);

    const _isMounted = useRef(false);
    const userTableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [pages, setPages] = useState(null);
    const [data, setData] = useState([]);
    const sort = [{"id": "id","desc": false}];  

    const [myFiles, setMyFiles] = useState([]);
    
    const getFileList = async() => {
        let userID = sessionStorage.getItem('userID');
        let webID = sessionStorage.getItem('webID');
        const api_call = await fetch("https://filemanager.3plains.com/fmsfiles.php?f="+webID+userID);
        const filelist = await api_call.json();
        setPosts(filelist.data);

   
    }

    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles]);
        document.getElementById("listaside").style.display= "inline";
        acceptedFiles.forEach(function(value, key) {
            let userID = sessionStorage.getItem('userID');
            const formData = new FormData();
			formData.append('file', value);
      		formData.append('userID', userID);
			//fetch('https://filemanager.3plains.com/fmsupload.php', {
            fetch('https://fms.3plains.com/fmsupload.php', {
				method : 'POST',
				body : formData
			})
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                getFileList();
                const getComments = async () => {
                    let userID = sessionStorage.getItem('userID');
                    let webID = sessionStorage.getItem('webID');
                    const res = await fetch(
                        "https://filemanager.3plains.com/fmsfiles.php?_page=1&_limit={limit}&f="+webID+userID
                    );
                    const filelist = await res.json();
                    const data = filelist.data;
                    console.log(data);
                    const total = data[0].total;
                    if (data) {
                        console.log("total: "+total);
                        setpageCount(Math.ceil(total / limit));
                        //console.log(Math.ceil(total/12));
                        setItems(data);
                    }
                  };
              
                  getComments();
                  removeAll();
            })
            .catch((error) => console.log(error));

        })
        
        

      }, [myFiles])

    const removeFile = file => () => {
        const newFiles = [...acceptedFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const removeAll = () => {
        setMyFiles([]);
        document.getElementById("listaside").style.display= "none";
    }

    const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    
    const files = myFiles.map(file => (
        <li key={file.path}>        
        <div className="listfile"><div className="spinner"></div>{file.path} - {file.size} bytes{" "}</div>
        </li>
    ))

    useEffect(() => {
        document.getElementById("listaside").style.display= "none";
        _isMounted.current = true;
        // let userID = sessionStorage.getItem('userID');
        // let webID = sessionStorage.getItem('webID');
        // const api_call = fetch("https://filemanager.3plains.com/fmsfiles.php?f="+webID+userID);
        // const filelist = api_call.json();
        // setPosts(filelist.data);
        const getComments = async () => {
            let userID = sessionStorage.getItem('userID');
            let webID = sessionStorage.getItem('webID');
            const res = await fetch(
                "https://filemanager.3plains.com/fmsfiles.php?_page=1&_limit={limit}&f="+webID+userID
            );
            const filelist = await res.json();
            const data = filelist.data;
            console.log(data);
            const total = data[0].total;
            if (data) {
                console.log("total: "+total);
                setpageCount(Math.ceil(total / limit));
                //console.log(Math.ceil(total/12));
                setItems(data);
            }
          };
      
          getComments();
        }, [limit]);

    const fetchComments = async (currentPage) => {
        let userID = sessionStorage.getItem('userID');
        let webID = sessionStorage.getItem('webID');
        const res = await fetch(
            "https://filemanager.3plains.com/fmsfiles.php?_page="+currentPage+"&_limit={limit}&f="+webID+userID
        );
        const filelist = await res.json();
        const data = filelist.data;
        console.log(data);
        return data;
    };    

    const handlePageClick = async (data) => {
        //console.log(data.selected);
    
        let currentPage = data.selected + 1;
    
        const commentsFormServer = await fetchComments(currentPage);
    
        setItems(commentsFormServer);
        
        // scroll to the top
        //window.scrollTo(0, 0)
    };

    return (
        <div>
        <main>
            <section className="d-flex justify-content-center align-items-center" id="section_1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12 main-content offset-md-2" id="main_content">
                            <div {...getRootProps()} className="dropzone_window">
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                }
                            </div> 
                            <aside id="listaside">
                                <p>File progress:</p>
                                <ul id="filelist">{files}</ul>
                            </aside>
                               
                        </div>
                    </div>
                    
                    
                </div>
            </section>

            <section className="d-flex justify-content-center align-items-center" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2" id="files_content">
                            <table className="table table-hover" id="files_table">
                                <thead>
                                    <tr>
                                        <th>View</th>
                                        <th>Filename</th>
                                        <th>Size</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                    

                                    {items.map((item) => {
                                            return (
                                                <tr key={item.id} >    
                                                    <td><div dangerouslySetInnerHTML={{__html:item.display}}></div></td>
                                                    <td><div dangerouslySetInnerHTML={{__html:item.filename}}></div></td>
                                                    <td><div dangerouslySetInnerHTML={{__html:item.size + " KB"}}></div></td>
                                                    <td><div dangerouslySetInnerHTML={{__html:item.fdate}}></div></td>
                                                    <td><div className="button"><div className="delete-btn">Delete</div></div></td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td colSpan="5">
                                            <ReactPaginate
                                                previousLabel={"previous"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        </div>
    );
}

export default Upload;